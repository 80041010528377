import { useRouter } from 'next/router';
import { useContext } from 'react';
import { TeamsDataContext } from '@components/context/TeamsContext';
import Button from '@atoms/Button';
import { StreamDataContext } from '@components/context/StreamContext';
import DropdownDestination from '@molecules/DropDownDestination';
import Logo from '@atoms/Logo';
import Container from '@atoms/Container';
import UserGuide from '@components/UserGuide';

const Header = (props) => {
  const router = useRouter();
  const { showUserGuide, setShowUserGuide } = useContext(StreamDataContext);
  const { ownTeam } = useContext(TeamsDataContext);
  const { doLogout } = useContext(TeamsDataContext);

  const logout = () => {
    doLogout();
    router.push('/');
  };

  const showBanner = () => (
    ['home', 'landing'].includes(props?.page)
    && ownTeam?.billing_account?.purchase_plan?.name === 'Free Plan'
  );

  return (
    <div className={`sticky top-0 w-full bg-white z-40 ${!props.shape && ''}`}>
      {showUserGuide && <UserGuide onCancel={() => setShowUserGuide(false)} />}
      <Container type='type0' className={'py-2 mx-auto'}>
        <div className="flex items-center justify-between top-header">
          {
            !props.logoClick && <Logo id="saLogoId" src="/images/logos/logo_tag_black.svg" className="max-w-[156px] block" />
          }

          {
            props.logoClick && <Logo id="saLogoId" src="/images/logos/logo_tag_black.svg" className="max-w-[156px] block" />
          }

          <div className="flex items-center justify-end w-full">

            {props.isLoggedIn && !props.noLgBtn && props.nav
              && <>
                <DropdownDestination logout={logout} page={props.page} />
              </>
            }

            {
              props.logout
              && <Button className="mr-2 font-semibold lg:text-base lg:leading-5 rounded-lg"
                type="ghost" onClick={logout}>Logout</Button>
            }

          </div>
        </div>
      </Container >

      {showBanner()
        ? <div className='bg-neutral-10'>
          <div className="bg-semantic-successLight-50 flex justify-center w-full p-2 text-sm leading-6">
            You’re on a Free Plan. For branding, more participants, etc.
            <a
              className='text-primary-500 cursor-pointer font-semibold'
              onClick={() => router.push('/pricing')}
            >
              &nbsp;Upgrade Now
            </a>
          </div>
        </div>
        : null
      }
    </div >
  );
};
export default Header;
