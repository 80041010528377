import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'react-tooltip';

const Button = React.forwardRef(({
  children, onClick, type, onMouseUp, onMouseEnter, loaderBlack,
  onMouseLeave, onMouseDown, disabled, dotsMain,
  isLoading = false, autoLoaderTimeout, tabIndex, showToolTip = false,

  padding = 'py-2.5 px-9',

  lineHeight = 'leading-6',

  radius = 'rounded-lg',

  fontWeight = 'font-semibold',

  fontSize = 'text-sm',

  ...property
}, ref) => {
  const defaultClasses = ` flex items-center justify-center text-center overflow-hidden ${padding} ${lineHeight} ${radius} ${fontWeight} ${fontSize} `;

  const [loading, setLoading] = useState(isLoading);

  const buttonClasses = {
    primary: ` ${defaultClasses} ${loading ? 'cursor-not-allowed' : 'primaryActiveBtn'} primaryBtn font-semibold bg-black text-white hover:text-white focus:outline-none active:shadow-none`,
    secondary: ` ${defaultClasses} ${loading ? 'cursor-not-allowed' : 'secondaryActiveBtn'} secondaryBtn font-semibold bg-primary-500 text-white hover:text-white dark:text-white dark:hover:text-white hover:bg-primary-600 focus:outline-none active:shadow-none`,
    ghost: ` ${defaultClasses} ${loading ? 'cursor-not-allowed' : 'ghostActiveBtn'} border-none ghostBtn font-semibold text-black bg-neutral-20 hover:bg-neutral-30 active:shadow-none`,
    disabled: ` ${defaultClasses} cursor-not-allowed border border-neutral-40 text-neutral-40 `,
    blue: ` ${defaultClasses} ${loading ? 'cursor-not-allowed' : 'blueActiveBtn'} blueBtn bg-blue-500 text-neutral-20 hover:text-white dark:text-neutral-20 dark:hover:text-white focus:text-white hover:shadow-4xl focus:outline-none focus:shadow-4xl`
  };

  const getCloak = (visible) => {
    if (!visible) {
      return 'z-0 invisible';
    }
    return 'z-10 visible';
  };

  const getAnimationColor = () => {
    if (loaderBlack) return 'lightup-black';
    if (type && type.match('primary')) {
      return 'lightup-primary';
    }
    if (type && type.match('secondary')) {
      return 'lightup-secondary';
    }
    return 'lightup-default';
  };

  const performClick = (evt) => {
    if (loading || disabled) {
      return false;
    }

    if (onClick) {
      onClick(evt);
      if (autoLoaderTimeout) {
        setLoading(true);
        setTimeout(() => setLoading(false), autoLoaderTimeout);
      }
    }

    return null;
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <div className={`${showToolTip && 'container'} ${property.containerClassName}`}>
      <button
        data-tooltip-id={`${showToolTip && 'button-tooltip'}`}
        data-tooltip-content={`${showToolTip && 'Please use the StreamAlive Chrome extension to connect to your event.'}`}
        tabIndex={tabIndex}
        ref={ref}
        {...property}
        type={disabled ? 'disabled' : 'button'}
        onClick={performClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        onMouseDown={onMouseDown}
        className={disabled ? `${buttonClasses.disabled} ${property.className}` : `${buttonClasses[type]} ${property.className} transition-all`}
      >
        {showToolTip && <Tooltip id="button-tooltip" className='tooltip' place='top-start' />}
        <div className="relative w-full">
          <div className={`absolute h-full w-full flex justify-center ${getCloak(loading)}`}>
            <div className={`relative h-[99%] w-full m-auto items-center ${property.sm ? 'new-loader-dots-small' : 'new-loader-dots'} ${getAnimationColor()} flex justify-center ${dotsMain}`}>
              <div></div>
              <div></div>
              <div></div>
              {!property.sm && (<div></div>)}
            </div>
          </div>
          <div className={`flex justify-center ${getCloak(!loading)}`}>
            {children}
          </div>
        </div>
      </button>
    </div >

  );
});
export default Button;

Button.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.string,
  className: PropTypes.string
};
