import React, {
  useState, createRef, useContext, useEffect
} from 'react';
import { createPopper } from '@popperjs/core';
import router from 'next/router';
import Text from '@atoms/Text';
import Avatar from '@atoms/Avatar';
import Image from '@atoms/Image';
import Item from '@atoms/DropDownItem';
import ItemHeader from '@atoms/DropDownItemHeader';
import ItemContainer from '@atoms/DropDownItemContainer';
import useOutsideClickDetect from '@lib/hooks/useOutsideClickDetect';

import { StreamDataContext } from '@components/context/StreamContext';
import { TeamsDataContext } from '@components/context/TeamsContext';

import { handleKeyStrokes } from '@services/utils.service';
import { isZoomApp, zoomAppBasePath } from '@zoom-app/helpers';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';

const navLinks = ['/presentations', '/stats', '/settings', '/my-teams', '/pricing', '/sessions'];

const DropdownDestination = (props) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const [profilePopoverShow, setProfilePopoverShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState('Team Settings');
  const btnDropdownRef = createRef();
  const popoverDropdownRef = createRef();
  const profilePopoverRef = createRef();
  const profileDropdownRef = createRef();
  const {
    currentTeam, filterTeamsByRole, selectTeam, ownTeam,
    user,
    teams,
    isSelected,
    initTeam,
    isTeamOwner
  } = useContext(TeamsDataContext);
  const { setPreventRoute, preventRoute, setRoute } = useContext(StreamDataContext);
  useOutsideClickDetect(dropdownPopoverShow, 'DropdownDestination', setDropdownPopoverShow);
  useOutsideClickDetect(profilePopoverShow, 'DropdownDestination', setProfilePopoverShow);

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      setPreventRoute(false);
    });
    return () => {
      router.events.off('routeChangeComplete');
    };
  }, []);

  const openDropdownPopover = async () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [10, 12]
          }
        }
      ]
    });
    setDropdownPopoverShow(true);
    await initTeam();
  };

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const openProfilePopover = async () => {
    createPopper(profilePopoverRef.current, profileDropdownRef.current, {
      placement: 'bottom-end',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [10, 20]
          }
        }
      ]
    });
    setProfilePopoverShow(true);
    await initTeam();
  };

  const closeProfilePopover = () => {
    setProfilePopoverShow(false);
  };

  const routeTo = (path) => {
    if (preventRoute) {
      setRoute(path);
    } else router.push(path);
  };

  const handlePopover = (e) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      closeDropdownPopover();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handlePopover);
    return () => window.removeEventListener('keydown', handlePopover);
  }, []);

  if (!currentTeam) {
    return <></>;
  }

  const getActiveLinkStyle = (link) => (router.router.pathname.startsWith(link) ? 'text-primary-500' : '');

  return (
    <div id="DropdownDestination" className="z-50 drop-down-wrap">
      <div className='flex items-center'>
        <Text
          id="menuHomeId"
          tabIndex={1}
          onKeyDown={handleKeyStrokes}
          children='Home'
          className={`p-1 hover:text-primary-500  ${!navLinks.some((link) => router.router.pathname.startsWith(link)) ? 'text-primary-500' : ''} lg:text-sm md:leading-5 md:text-xs text-tiny flex justify-center items-center lg:mr-8 mr-3 cursor-pointer`}
          onClick={() => (isZoomApp() ? routeTo(zoomAppBasePath) : routeTo('/'))}
        />
        <Text
          id="menuRosId"
          tabIndex={1}
          onKeyDown={handleKeyStrokes}
          children='Presentations'
          className={`p-1 hover:text-primary-500 ${getActiveLinkStyle('/presentations')} lg:text-sm md:leading-5 md:text-xs text-tiny flex justify-center items-center lg:mr-8 mr-3 cursor-pointer`}
          onClick={() => routeTo('/presentations')}
        />
        <Text
          tabIndex={1}
          children='Sessions'
          id="top-nav-sessions-link"
          onKeyDown={handleKeyStrokes}
          className={`p-1 hover:text-primary-500 ${getActiveLinkStyle('/sessions')} lg:text-sm md:leading-5 md:text-xs text-tiny flex justify-center items-center lg:mr-8 mr-3 cursor-pointer`}
          onClick={() => routeTo('/sessions')}
        />
        <Text
          id="menuReportId"
          tabIndex={1}
          onKeyDown={handleKeyStrokes}
          children='Reports'
          className={`p-1 hover:text-primary-500 ${getActiveLinkStyle('/stats')} lg:text-sm md:leading-5 md:text-xs text-tiny flex justify-center items-center lg:mr-8 mr-3 cursor-pointer`}
          onClick={() => routeTo('/stats')}
        />
        <Text
          id="menuSettingsId"
          tabIndex={1}
          onKeyDown={handleKeyStrokes}
          children='Settings'
          className={`p-1 hover:text-primary-500 ${getActiveLinkStyle('/settings')} lg:text-sm md:leading-5 md:text-xs text-tiny flex justify-center items-center lg:mr-8 mr-3 cursor-pointer`}
          onClick={() => routeTo('/settings')}
        />

        <button
          id="menuAccountDDId"
          tabIndex={1}
          onKeyDown={handleKeyStrokes}
          className={'flex items-center bg-neutral-10 px-2 py-2 rounded-lg border-focused lg:text-sm md:leading-5 md:text-xs text-tiny justify-end mr-0.5 cursor-pointer max-w-[200px] overflow-clip  '}
          ref={btnDropdownRef}
          onClick={() => {
            dropdownPopoverShow
              ? closeDropdownPopover()
              : openDropdownPopover(); closeProfilePopover();
          }}
        >
          <div className="lg:w-52 w-auto flex items-center justify-center overflow-hidden">
            <div className="mr-3">
              <Avatar
                w='8'
                h='8'
                name={currentTeam?.billing_account?.name} />
            </div>
            <div className="w-full sm:block">
              <div className='line-clamp-1 text-sm text-left leaning-3.5 font-semibold text-neutral-60' >{currentTeam?.billing_account?.name}</div>
            </div>
          </div>
          {dropdownPopoverShow ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </button>

        <div className='flex items-center ml-8 cursor-pointer border-focused'
          tabIndex={1}
          onKeyDown={handleKeyStrokes}
          ref={profilePopoverRef}
          onClick={() => {
            profilePopoverShow
              ? closeProfilePopover()
              : openProfilePopover(); closeDropdownPopover();
          }}>
          <Avatar
            w='8'
            h='8'
            name={user.email}
            isProfile={true}
            isOpen={profilePopoverShow} />
        </div>
      </div >

      <div
        ref={popoverDropdownRef}
        className={
          `${(dropdownPopoverShow ? '' : 'hidden ')} -mt-150 h-fit w-61.5 py-2.5 z-50 bg-white list-none rounded-lg text-left shadow-md-1`
        }>

        {currentTeam && (
          <>
            <Item>
              <ItemHeader text="My Teams" />
              {teams.map((team, index) => (
                <ItemContainer
                  tabIndex={1}
                  onKeyDown={handleKeyStrokes}
                  disabled={isSelected(team.billing_account.id)}
                  className="border-focused"
                  key={index}
                  onClick={() => {
                    if (isSelected(team.billing_account.id)) return;
                    selectTeam(team);
                    router.push('/');
                    closeDropdownPopover();
                  }}>
                  <div className="text-sm flex px-1.5">
                    <div className="w-6 text-center self-center">
                      <Avatar
                        disabled={isSelected(team.billing_account.id)}
                        w="6"
                        h="6"
                        textClass="text-xs"
                        bgClass={'rounded-md'}
                        name={team.billing_account.name}
                      />
                    </div>
                    <div className='flex flex-col ml-2 truncate'>
                      <div className={`${team.id === currentTeam.id ? 'text-black' : 'text-neutral-60'} text-sm leading-5 font-medium  pt-1.5 truncate`}>
                        {team.billing_account.name}
                      </div>
                      <div className={`${team.id === currentTeam.id ? 'text-black' : 'text-neutral-40'} text-xs leading-4 font-medium pb-1.5 truncate`}>
                        {team?.billing_account?.owner?.email}
                      </div>
                    </div>
                  </div>
                </ItemContainer>))}
            </Item>
          </>
        )}

      </div >

      <div
        ref={profileDropdownRef}
        className={
          `${(profilePopoverShow ? '' : 'hidden ')} -mt-150 h-fit w-61.5 py-2.5 z-50 bg-white list-none rounded-lg text-left shadow-md-1`
        }>
        <Item>
          <ItemHeader text={user.email} />

          <hr className="my-3 text-neutral-10 border mx-auto"></hr>

          {
            filterTeamsByRole('owner').map((team) => (
              <ItemContainer key={team.id}
                tabIndex={1}
                onKeyDown={handleKeyStrokes}
                className="border-focused cursor-pointer"
                onClick={() => { setSelectedItem('Team Settings'); router.push(`/my-teams/${team?.billing_account?.id}`); closeProfilePopover(); }}
              >
                <div className="text-sm flex py-1.5 px-2">
                  <div className="w-6 text-center self-center">
                    <Image src="/images/Teams.svg" className="inline w-5 h-5" />
                  </div>
                  <div className="ml-1 self-center">
                    <span className={`leading-5 ${selectedItem === 'Team Settings' ? 'text-neutral-60' : 'text-neutral-60'}`}>Team Settings</span>
                  </div>
                </div>
              </ItemContainer>
            ))
          }

          {
            !ownTeam?.billing_account?.appsumo_invoice_item_uuid ? (
              <ItemContainer
                disabled={!isTeamOwner()}
                tabIndex={1}
                onKeyDown={handleKeyStrokes}
                className="border-focused"
                onClick={() => { setSelectedItem('Pricing'); router.push('/pricing'); closeProfilePopover(); }}
              >
                <div className="text-sm flex py-1.5 px-2">
                  <div className="w-6 text-center">
                    <Image src="/images/Star.svg" className="inline-block w-5 h-5" />
                  </div>
                  <div className="ml-2 w-full flex">
                    <div className={`flex-auto self-center text-sm leading-5 text-neutral-60 ${selectedItem === 'Pricing' ? 'text-black' : 'text-neutral-60'}`}>{ownTeam?.billing_account?.purchase_plan?.name}</div>
                    <div className="flex-auto self-center text-right py-0 font-semibold rounded-sm text-tiny8">
                      {(ownTeam?.billing_account?.purchase_plan?.name.match(/(Free Plan|Basic Plan)/)) ? <span className="sa-nav-inner-pill text-white">GET PRO</span> : null}
                    </div>
                  </div>
                </div>
              </ItemContainer>
            ) : (
              <ItemContainer
                tabIndex={1}
                onKeyDown={handleKeyStrokes}
                className="border-focused cursor-pointer"
                onClick={() => { setSelectedItem('App Sumo Pricing'); router.push(`/appSumoPricingPlan/${ownTeam?.billing_account?.purchase_plan?.slug}`); closeProfilePopover(); }}
              >
                <div className="text-sm flex py-1.5 px-2">
                  <div className="w-6 text-center">
                    <Image src="/images/Star.svg" className="inline-block w-5 h-5" />
                  </div>
                  <div className="ml-2 w-full flex">
                    <div className={`flex-auto self-center text-sm leading-5 ${selectedItem === 'App Sumo Pricing' ? 'text-neutral-60' : 'text-neutral-60'}`}>{ownTeam?.billing_account?.purchase_plan?.name}</div>
                    <div className="flex-auto self-center text-right py-0 font-semibold rounded-sm text-tiny8">
                      <span className="sa-nav-inner-pill text-white">Lifetime</span>
                    </div>
                  </div>
                </div>
              </ItemContainer>
            )
          }

          {ownTeam?.billing_account?.purchase_plan?.name !== 'Free Plan' && <ItemContainer
            tabIndex={1}
            onKeyDown={handleKeyStrokes}
            onClick={() => { setSelectedItem('Billing'); window.open(process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_LINK, '_ blank'); closeProfilePopover(); }}
            className="mb-2 mt-3  cursor-pointer border-focused" >
            <div className="text-sm flex py-1.5 px-2">
              <div className="w-6 text-center self-center">
                <Image src="/images/billing.svg" className="inline w-5 h-5" />
              </div>
              <div className={`ml-2 self-center leading-5 ${selectedItem === 'Billing' ? 'text-neutral-60' : 'text-neutral-60'}`}>
                Billing
              </div>
            </div>
          </ItemContainer>}

          <ItemContainer
            className="border-focused mb-2 cursor-pointer"
            tabIndex={1}
            onKeyDown={handleKeyStrokes}
            onClick={() => { setSelectedItem('Help'); window.open('http://help.streamalive.com', '_blank'); closeProfilePopover(); }}>
            <div className="text-sm flex py-1.5 px-2">
              <div className="w-6 text-center self-center">
                <Image src="/images/help.svg" className="inline w-5 h-5" />
              </div>
              <div className="ml-2 self-center">
                <Text className={`text-sm leading-5 ${selectedItem === 'Help' ? 'text-neutral-60' : 'text-neutral-60'}`}>Help</Text>
              </div>
            </div>
          </ItemContainer>

          <hr className="my-3 text-neutral-10 border mx-auto"></hr>

          <ItemContainer
            className="border-focused mb-2 cursor-pointer"
            tabIndex={1}
            onKeyDown={handleKeyStrokes}
            onClick={() => { setSelectedItem('Privacy Policy'); window.open('https://www.streamalive.com/privacy', '_blank'); closeProfilePopover(); }}>
            <div className="text-sm flex py-1.5 px-2">

              <div className="ml-2 self-center">
                <Text className={`text-sm leading-5 ${selectedItem === 'Privacy Policy' ? 'text-neutral-60' : 'text-neutral-60'}`}>Privacy Policy</Text>
              </div>
            </div>
          </ItemContainer>

          <ItemContainer
            className="border-focused mb-2 cursor-pointer"
            tabIndex={1}
            onKeyDown={handleKeyStrokes}
            onClick={() => { setSelectedItem('Terms'); window.open('https://www.streamalive.com/terms-of-service', '_blank'); closeProfilePopover(); }}>
            <div className="text-sm flex py-1.5 px-2">
              <div className="ml-2 self-center">
                <Text className={`text-sm leading-5 ${selectedItem === 'Terms' ? 'text-neutral-60' : 'text-neutral-60'}`}>Terms</Text>
              </div>
            </div>
          </ItemContainer>

          <hr className="my-3 text-neutral-10 border mx-auto"></hr>

          <ItemContainer
            className="border-focused mb-2 cursor-pointer"
            tabIndex={1}
            onKeyDown={handleKeyStrokes}
            onClick={() => { props.logout(); closeProfilePopover(); }}>
            <div className="text-sm flex py-1.5 px-2">
              <div className="w-6 text-center self-center">
                <Image src="/images/Logout.svg" className="inline w-5 h-5" />
              </div>
              <div className="ml-2 self-center">
                <Text className="text-sm leading-5 text-neutral-60">Logout</Text>
              </div>
            </div>
          </ItemContainer>
        </Item >
      </div>

    </div >
  );
};

export default DropdownDestination;
