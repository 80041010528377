import { useEffect, useRef } from 'react';

function useOutsideClickDetect(isVisible, id, cb) {
  const ref = useRef(null);
  useEffect(() => {
    if (!isVisible) return undefined;
    function handleClickOutside(event) {
      const elem = ref.current || document.getElementById(id);
      if (elem && !elem.contains(event.target)) cb(false);
    }

    function handleEscapeKey(e) {
      if (e.key === 'Escape') cb(false);
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isVisible]);
  return ref;
}

export default useOutsideClickDetect;
