import { Spinner } from '@nextui-org/react';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { v4 } from 'uuid';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading?: boolean;
  tabIndex?: number;
  tooltipMessage?: string;
  theme?: 'default' | 'danger';
  variant?: 'primary' | 'secondary' | 'tertiary';
  addonLeft?: React.ReactNode;
  addonRight?: React.ReactNode;
  sm?: boolean;
  size?: 'md' | 'lg' | 'custom';
  fontWeight?: string;
  fontSize?: string;
  fullWidth?: boolean;
  isIcon?: boolean;
  roundedClass?: string;
  spinnerColor?: 'white' | 'default' | 'current' | 'primary' | 'secondary' | 'success' | 'warning' | 'danger'
}

const Button = ({
  children,
  onClick,
  type = 'button',
  isLoading = false,
  tabIndex,
  tooltipMessage = '',
  theme = 'default',
  variant = 'primary',
  addonLeft,
  addonRight,
  sm = true,
  size = 'md',
  fontWeight = 'font-semibold',
  fontSize = 'text-sm',
  fullWidth = false,
  roundedClass = 'rounded-md',
  isIcon = false,
  spinnerColor = 'primary',
  ...property
}: ButtonProps,
ref: React.Ref<HTMLButtonElement>) => {
  const buttonId = v4();

  const getPadding = () => {
    switch (size) {
      case 'custom':
        return 'px-2 py-1.5';
      case 'lg':
        return `${isIcon ? 'px-2' : 'px-5'} h-9.5`;
      case 'md':
      default:
        return `${isIcon ? 'px-2' : 'px-4'} h-8`;
    }
  };

  const showPointerEffects = (classes: string) => (!(isLoading || property.disabled) ? `${classes} active:scale-[.98]` : 'cursor-not-allowed');

  const darkModeClasses = {
    primary: '',
    secondary: `dark:bg-grey-100 dark:text-grey-900 ${showPointerEffects('dark:hover:bg-grey-200  dark:active:bg-grey-200')}`,
    // tertiary: `dark:bg-black dark:text-white ${showPointerEffects('dark:hover:bg-[#252525] dark:active:bg-[#4d4d4d]')} dark:border dark:border-grey-200 dark:focus-visible:border-white`,
    tertiary: ''
  };

  const defaultClasses = `
  font-semibold flex items-center justify-center
  text-center overflow-hidden leading-6
  transition-all active:transform
  disabled:opacity-30 group
  ${darkModeClasses[variant]} ${roundedClass}
  ${getPadding()} ${fontWeight} ${fontSize} ${fullWidth ? 'w-full' : 'w-fit'}`;

  const buttonTheme = {
    default: {
      primary: `bg-red-500 text-white ${showPointerEffects('hover:bg-red-600 active:bg-red-700')}`,
      secondary: `bg-grey-100 text-grey-900 ${showPointerEffects('hover:bg-grey-200 active:bg-grey-300')}`,
      tertiary: `bg-transparent text-grey-900 ${showPointerEffects('hover:bg-grey-100 active:bg-grey-200')} border border-grey-200 focus-visible:border-black`
    },
    danger: {
      primary: `bg-pure-red-500 text-white ${showPointerEffects('hover:bg-pure-red-600 active:bg-pure-red-700')}`,
      secondary: '',
      tertiary: ''
    }
  };
  const getCloak = (visible: boolean) => {
    if (!visible) return 'z-0 invisible';
    return 'z-10 visible';
  };

  const getAnimationColor = () => {
    let className = 'animate-lightup-red';
    if (theme === 'danger') className = 'animate-lightup-orange';
    return `${className} dark:animate-lightup-dark`;
  };

  const performClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isLoading || property.disabled) return;
    onClick?.(e);
  };

  return (
    <button
      data-tooltip-id={`${tooltipMessage ? `button-tooltip-${buttonId}` : ''}`}
      data-tooltip-content={tooltipMessage}
      tabIndex={isLoading ? -1 : tabIndex}
      {...property}
      ref={ref}
      type={type}
      onClick={performClick}
      className={`
        ${defaultClasses}
        ${buttonTheme[theme]?.[variant]}
        ${property.className || ''}
       `}
    >
      {tooltipMessage && <Tooltip id={`button-tooltip-${buttonId}`} className='tooltip max-w-56' place='top-start' />}
      <div className="flex justify-center w-full">
        {isLoading && <div className='flex items-center gap-2'>
          <Spinner
            className='my-auto'
            color={spinnerColor}
            size='sm'/>
          <span>{children}</span>
        </div>}
        {!isLoading && <div className={` flex items-center justify-center gap-1 ${getCloak(!isLoading)}`}>
          {addonLeft} <span className='whitespace-nowrap'>{children}</span> {addonRight}
        </div>}
      </div>
    </button>

  );
};
export default React.forwardRef(Button);
