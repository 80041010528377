'use client';

import { useRouter } from 'next/router';
import { getAuth } from '@services/identity.service';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { logsToAwsCloudWatch } from 'services/logger.service';
import { handleCountAndRedirect } from '@services/utils.service';

export default function ErrorBoundaryWrapper({ children }) {
  const router = useRouter();

  function errorBoundaryHandler(error, componentStack) {
    const auth = getAuth();

    const body = {
      logMessage: error.message ? error.message : 'BrowserError(error boundary wrapper): Error occurred in the component',
      logData: {
        userId: auth?.id || '', errorStack: error.stack, message: error.message, componentStack
      }
    };
    logsToAwsCloudWatch(body);
  }

  const ErrorFallback = ({ resetErrorBoundary }) => {
    useEffect(() => {
      const retryInterval = setInterval(() => {
        if (process.env.NEXT_PUBLIC_ENV_URL === 'development') return;
        resetErrorBoundary();
      }, 2000);

      return () => {
        clearInterval(retryInterval);
      };
    }, [resetErrorBoundary]);

    return (<div>
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <div className="font-Poppins font-normal bg-white p-8 rounded-lg shadow-md text-center">
          <p className="text-2xl font-semibold text-red-600 mb-4">An error occurred!</p>
          <p className="text-xl text-black mb-6">Please try again or contact support if the problem persists.</p>
          <div className="flex justify-center gap-6">
            <p className="text-xl text-black mb-6">Retrying...</p>
          </div>
        </div>
      </div>
    </div>);
  };

  return (
    <ErrorBoundary
      onError={errorBoundaryHandler}
      FallbackComponent={(props) => <ErrorFallback {...props} />}
      onReset={() => {
        handleCountAndRedirect(router);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
