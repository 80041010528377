import Button from '@atoms/new/button';
import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@atoms/new/modal';
import { Tab, Tabs } from '@atoms/new/tab';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import MailRoundedIcon from '@mui/icons-material/MailRounded';
import React, { useContext, useState } from 'react';
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { TeamsDataContext } from '@components/context/TeamsContext';
import moment from 'moment';
import WorkspaceName from './WorkspaceName';
import InviteMember from './InviteMember';
import { useQuery } from '@tanstack/react-query';
import { getTeamByIdServiceSide, removeTeamMember } from '@services/youtube-platform.service';
import { getAuth } from '@services/identity.service';
import CellData from './CellData';

interface WorkspaceProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
}

const activeColumns = [
  {
    key: 'member',
    label: 'Member',
  },
  {
    key: 'date',
    label: 'Join Date',
  },
  {
    key: 'role',
    label: 'Role',
  },
];
const pendingColumns = [
  {
    key: 'member',
    label: 'Email',
  },
  {
    key: 'date',
    label: 'Last Sent',
  },
  {
    key: 'role',
    label: 'Revoke',
  },
];

export default function Workspace({
  isOpen,
  onOpenChange,
}: WorkspaceProps) {
  const [selectedKey, setSelectedKey] = useState('active');
  const columns = selectedKey === 'active' ? activeColumns : pendingColumns;
  const {
    ownTeam,
    currentTeam,
    updateTeam,
    setTeamMessages,
    // filterTeamsByRole,
  } = useContext(TeamsDataContext);

  // const owner = filterTeamsByRole('owner')?.[0];

  const { data: teams, refetch } = useQuery({
    queryKey: ['current-team-query', currentTeam?.billing_account.id],
    queryFn: () => getTeamByIdServiceSide(currentTeam.billing_account.id, getAuth()),
    enabled: !!currentTeam,
  });

  const getRoleName = (role: string) => {
    switch (role) {
      case 'owner':
        return 'Admin';
      default:
        return 'Editor & Moderator';
    }
  };

  type Team = {
    key: string;
    member: string;
    date: string;
    role: string;
  }
  let activeMembers: Team[] = [];
  let pendingMembers: Team[] = [];
  teams?.entity?.members?.forEach((team: any) => {
    if (team.accepted_on) {
      activeMembers.push({
        key: team.id,
        member: team.email,
        date: moment(team.accepted_on).format('MMM DD, YYYY, h:mm A'),
        role: getRoleName(team.role)
      });
    } else {
      pendingMembers.push({
        key: team.id,
        member: team.email,
        date: moment(team.created_at).format('MMM DD, YYYY, h:mm A'),
        role: 'Pending',
      });
    }
  });

  const rows = selectedKey === 'active' ? activeMembers : pendingMembers;

  const classNames = React.useMemo(
    () => ({
      wrapper: ['max-h-[382px]', 'max-w-3xl', 'shadow-none', 'px-0'],
      th: ['bg-transparent', 'text-default-500', 'border-b', 'border-divider'],
      td: [
        // changing the rows border radius
        // first
        'group-data-[first=true]:first:before:rounded-none',
        'group-data-[first=true]:last:before:rounded-none',
        // middle
        'group-data-[middle=true]:before:rounded-none',
        // last
        'group-data-[last=true]:first:before:rounded-none',
        'group-data-[last=true]:last:before:rounded-none',
        'border-b border-grey-200',
      ],
    }),
    [],
  );

  const deleteItem = async(id: string) => {
    if (!teams) return;
    const response = await removeTeamMember(id, teams.entity.id);
    if (response.status) {
      updateTeam(response.entity);
      // onSuccess(response.entity);
      setTeamMessages([response.message]);
      refetch();
      // onCancel();
    } else {
      // setErrors([response.message]);
      console.error(response.message);
    }
  };
  const isOwner = currentTeam?.billing_account?.owner_id === ownTeam?.billing_account?.owner_id;

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      className='w-[800px]'
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className='mb-6'>Workspace settings</ModalHeader>
            <ModalBody>
              <WorkspaceName
                adminTeam={teams?.entity}
                onSuccess={refetch}
                isOwner={isOwner}
              />
              <InviteMember
                teams={teams?.entity}
                onSuccess={refetch}
                isOwner={isOwner}
              />
              <div className='mt-6 flex'>
                <Tabs aria-label=''
                  selectedKey={selectedKey}
                  onSelectionChange={k => setSelectedKey(k as string)}
                >
                  <Tab
                    key='active'
                    title={
                      <div className='flex items-center'>
                        <PeopleRoundedIcon fontSize='small' className='mr-2'/>
                      Active members
                        <span className='ml-1'>
                          {`(${activeMembers.length})`}
                        </span>
                      </div>
                    }/>
                  <Tab
                    key='invite'
                    title={
                      <div className='flex items-center'>
                        <MailRoundedIcon fontSize='small' className='mr-2'/>
                      Invitations
                        <span className='ml-1'>
                          {`(${pendingMembers.length})`}
                        </span>
                      </div>
                    }/>
                </Tabs>
              </div>
              {!rows.length && <div className='h-[100px] bg-grey-50 grid place-items-center mt-4 font-[500] text-grey-800'>
                    No pending invites.
              </div>}
              {!!rows.length && <Table
                aria-label="Members"
                classNames={classNames}
              >
                <TableHeader columns={columns} className='border-0 bg-white'>
                  {(column) => <TableColumn key={column.key} className=' text-sm text-grey-700'>{column.label}</TableColumn>}
                </TableHeader>
                
                <TableBody
                  items={rows}>
                  {(item) => (
                    <TableRow key={item.key}>
                      {(columnKey) => <TableCell className='h-12'>
                        <CellData
                          deleteItem={deleteItem}
                          columnKey={columnKey}
                          item={item}
                          isOwner={isOwner}
                        />
                      </TableCell>}
                    </TableRow>
                  )}
                </TableBody>
              </Table>}
            </ModalBody>
            <ModalFooter className='mt-2'>
              <Button
                onClick={onClose}
              >
                Done
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
