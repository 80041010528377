import { useContext } from 'react';
import { useTheme } from 'next-themes';
import { TeamsDataContext } from '@components/context/TeamsContext';
import { StreamDataContext } from '@components/context/StreamContext';
import { ZoomDataContext } from '@zoom-app/components/contexts/ZoomContext';

import useRedirect from '@zoom-app/hooks/useRedirect';

import Container from '@atoms/Container';
import UserGuide from '@components/UserGuide';

import DropdownDestination from './ZoomMenu';

const Header = (props) => {
  const { theme } = useTheme();

  const { showUserGuide, setShowUserGuide } = useContext(StreamDataContext);
  const { doLogout, user } = useContext(TeamsDataContext);
  const { initGuest, isLoggedIn } = useContext(ZoomDataContext);

  const { redirectToHome, getNextParam } = useRedirect();

  const logout = () => {
    doLogout();
    initGuest();
    redirectToHome();
  };

  return (
    <div className={`flex ${isLoggedIn() ? 'h-[60px]' : 'h-[104px]'} md:h-[57px] bg-white w-full z-50 border-b border-grey-200 items-center ${!props.shape && ''}`}>
      {showUserGuide && <UserGuide onCancel={() => setShowUserGuide(false)} />}
      <Container type='zoom' className={'!px-4 py-4 mx-auto'}>
        <div className={isLoggedIn() ? 'flex w-full' : 'grid grid-cols-1 md:grid-cols-2'}>
          <div className="flex items-center justify-center md:justify-start">
            <a
              onClick={redirectToHome}
              className="block w-[162.75px] h-[15.92px]"
            >
              <img
                id="saLogoId"
                src={theme === 'light' ? '/images/Logo.svg' : '/images/Logo.svg'}
                className=" cursor-pointer w-[162.75px] h-[15.92px] md:block"
              />
            </a>
            {process.env.NEXT_PUBLIC_APP_ENV !== 'production' && <div className="font-bold ml-2">[{process.env.NEXT_PUBLIC_APP_ENV.slice(0, 3)}]</div>}
          </div>
          <div className={`flex items-center w-full ${isLoggedIn() ? ' justify-end ' : ' justify-center md:justify-end mt-4 md:mt-0 '}`}>
            {user && (
              <DropdownDestination logout={logout} page={props.page} nextParam={getNextParam()} />
            )}
          </div>
        </div>
      </Container >
    </div >
  );
};
export default Header;
