import React, { useState } from 'react';
import InfoGuide from '../molecules/InfoGuide';
import Modal from '../molecules/Modal';
import Image from '../atoms/Image';
import Card from '../atoms/Card';

const interactionFeatures = [
  { heading: 'Power Polls', text: 'Follow these simple steps to set up and use our powerful polls—where users simply type options in the chat.', video: 'https://player.vimeo.com/video/730052827?title=0&byline=0&portrait=0&sidedock=0&controls=0&background=1' },
  { heading: 'Magic Maps', text: 'Learn how to use our real-time magical maps—the perfect ice-breaker and perfect for any location-themed question.', video: 'https://player.vimeo.com/video/730052849?title=0&byline=0&portrait=0&sidedock=0&controls=0&background=1' },
  { heading: 'Wonder Words', text: 'Watch this video to understand and use wonderful real-time word clouds that reflect your audience’s inputs.', video: 'https://player.vimeo.com/video/730052865?title=0&byline=0&portrait=0&sidedock=0&controls=0&background=1' },
  { heading: 'Quick Questions', text: 'Take a quick minute to save hours. Our quick questions feature magically identifies and separates legitimate questions in the chat.', video: 'https://player.vimeo.com/video/730052814?title=0&byline=0&portrait=0&sidedock=0&controls=0&background=1' }
];

const runOfShow = [
  { heading: 'Create Presentation', text: 'Success is 99% preparation! Learn how to create a Presentation for all the audience interactions you have in mind.', video: 'https://player.vimeo.com/video/729271743?title=0&byline=0&portrait=0&sidedock=0&controls=0&background=1' },
  { heading: 'Link/Unlink Presentation', text: 'Learn how to link (or unlink) your Presentation to your upcoming livestream or meeting on Zoom, Teams, or YouTube.', video: 'https://player.vimeo.com/video/729271684?title=0&byline=0&portrait=0&sidedock=0&controls=0&background=1' },
  { heading: 'Use Presentation', text: 'Use and reuse your Presentations in your livestreams and live events. This short video shows you how.', video: 'https://player.vimeo.com/video/729271661?title=0&byline=0&portrait=0&sidedock=0&controls=0&background=1' }
];

const UserGuide = ({ onCancel }) => {
  const [type, setType] = useState('getStarted');

  return (<>
    <Modal className="flex items-center justify-center rounded">
      <Card className='no-scrollbar1'>
        <div className='px-6 min-h-135.75 max-h-190 h-full'>
          <div className='flex justify-end'>
            <Image onClick={onCancel} src='/images/close-icon-black.svg' className='pt-3.5 cursor-pointer' />
          </div>
          <div className='flex mb-2'>
            <ul className='border-t-2 lg:border-0 border-neutral-20 flex gap-10'>
              <li className='relative'><p className={`cursor-pointer text-base leading-6 font-OpenSans font-semibold ${type === 'getStarted' ? 'text-black' : 'text-neutral-60'}`} onClick={() => setType('getStarted')}>Get Started</p> {type === 'getStarted' && <span className='lg:w-24 h-0.5 bg-primary-500 absolute lg:-right-2 -bottom-2.5 z-10'></span>} </li>
              <li className='relative'><p className={`cursor-pointer text-base leading-6 font-OpenSans font-semibold ${type === 'interactionFeatures' ? 'text-black' : 'text-neutral-60'}`} onClick={() => setType('interactionFeatures')}>Interaction Features</p> {type === 'interactionFeatures' && <span className='lg:w-40 h-0.5 bg-primary-500 absolute lg:-right-0.5 -bottom-2.5 z-10'></span>} </li>
              <li className='relative'><p className={`cursor-pointer text-base leading-6 font-OpenSans font-semibold ${type === 'runOfShow' ? 'text-black' : 'text-neutral-60'}`} onClick={() => setType('runOfShow')}>Presentation</p> {type === 'runOfShow' && <span className='lg:w-24 h-0.5 bg-primary-500 absolute lg:-right-0 -bottom-2.5 z-10'></span>} </li>
              <li className='relative'><p className={`cursor-pointer text-base leading-6 font-OpenSans font-semibold ${type === 'analytics' ? 'text-black' : 'text-neutral-60'}`} onClick={() => setType('analytics')}>Analytics</p> {type === 'analytics' && <span className='lg:w-20 h-0.5 bg-primary-500 absolute lg:-right-1.5 -bottom-2.5 z-10'></span>} </li>
            </ul>
          </div>
          <hr className='h-0.5 w-49 bg-neutral-20' />
          <div className='my-7.5'>
            {type === 'getStarted' && <iframe allowFullScreen className='min-h-135.75 max-h-190 w-full rounded' src={'https://player.vimeo.com/video/730365231?title=0&byline=0&portrait=0&sidedock=0&controls=0&background=1'} title="GetStarted video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            }
            {type === 'interactionFeatures' && interactionFeatures.map((feature, index) => (
              <InfoGuide
                heading={feature.heading}
                text={feature.text}
                video={feature.video}
                length={interactionFeatures.length}
                index={index}
              />
            ))}
            {type === 'runOfShow' && runOfShow.map((feature, index) => (
              <InfoGuide
                heading={feature.heading}
                text={feature.text}
                video={feature.video}
                length={runOfShow.length}
                index={index}
              />
            ))}
            {type === 'analytics' && <iframe allowFullScreen className='min-h-135.75 max-h-190 w-full rounded' src={'https://player.vimeo.com/video/730052794?title=0&byline=0&portrait=0&sidedock=0&controls=0&background=1'} title="Analytics video" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>}
          </div>
        </div>
      </Card>
    </Modal>
  </>);
};
export default UserGuide;
