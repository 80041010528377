import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getLoggedInUserAnalyticsData, googleAnalyticsPageView } from '@lib/gtag';
import * as gtm from '@lib/gtm';
import { usePostHog } from 'posthog-js/react';
import { useIsFirstRender } from '@uidotdev/usehooks';

const GoogleTagManager = ({ children }) => {
  const router = useRouter();
  const posthog = usePostHog();
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (isFirstRender) {
      const { email, user_id } = getLoggedInUserAnalyticsData();
      posthog?.identify(user_id, { email });
    }

    const handleRouteChange = (url) => googleAnalyticsPageView(url);
    const handlePageView = () => posthog?.capture('$pageview');
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('routeChangeComplete', gtm.pageview);
    router.events.on('routeChangeComplete', handlePageView);
    return () => {
      router.events.off('routeChangeComplete', gtm.pageview);
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('routeChangeComplete', handlePageView);
    };
  }, [router.events]);

  return children;
};

export default GoogleTagManager;
