import React from 'react';
import { Tooltip as NextUITooltip, type TooltipProps } from '@nextui-org/tooltip';

interface Props extends TooltipProps {
  children: React.ReactNode;
}
const container = {
  hover: {
    opacity: 1
  },

  normal: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },

  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};

function Tooltip({
  children,
  showArrow = true,
  disableAnimation = true,
  classNames,
  ...rest
}: Props, ref: React.Ref<HTMLDivElement>) {
  return (
    <NextUITooltip
      {...rest}
      ref={ref}
      // disableAnimation={disableAnimation}
      initial="normal"
      whileHover="hover"
      animate="hover"
      variants={container}
      delay={0}
      closeDelay={0}
      classNames={classNames || {
        base: ['before:bg-black dark:before:bg-black max-w-[335px]'],
        content: ['py-2 px-3 rounded-md text-xs', 'bg-black dark:bg-black text-white'],
      }}
      showArrow={showArrow}
    >
      {children}
    </NextUITooltip>
  );
}

export default React.forwardRef(Tooltip);
