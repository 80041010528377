import Button from '@atoms/Button';
import Heading from '@atoms/Heading';
import Card from '@atoms/Card';

import Container from '@atoms/Container';

const SwitchTeamWarning = ({ onCancel, onSuccess }) => <Container className="px-6 lg:px-20 md:px-14">
  <Card className="max-w-100 lg:max-w-210.5 mx-auto mt-40 h-auto flex flex-col items-center justify-items-center">
    <Heading type="h4" className="py-8 text-2xl font-semibold leading-8 ">Are you sure you want to switch team?</Heading>
    <div className="w-full md:max-w-100 md:mx-auto lg:max-w-full">
      <form autoComplete="off">
        <div className="m-5 text-center">
          Switching team will take you to the home page. <br />
          You may lose any existing changes that are not saved yet.
        </div>
        <div className="flex flex-row-reverse flex-wrap justify-center gap-6 mb-10 mt-15">
          <Button
            onClick={onSuccess}
            id="sa-form-btn-invite-member"
            type='secondary'
            padding='lg:px-15 py-2 px-10 lg:py-2.5'
            className={'bg-primary-500 text-white ml-0 '}>Switch Team</Button>
          <Button
            onClick={onCancel}
            id="sa-form-btn-invite-member-cancel"
            type='ghost'
            padding='lg:py-2.5 lg:px-15 px-10 py-2'
            className="text-center text-black border border-black ">Cancel</Button>
        </div>
      </form>
    </div>
  </Card >
</Container >;

export default SwitchTeamWarning;
