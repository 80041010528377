import Cookies from 'universal-cookie';
import { addDays } from 'date-fns';

const cookies = new Cookies();

export const setUniversalLoggedInCookie = () => cookies.set('IS_LOGGED_IN', 'true', { path: '/', domain: process.env.NEXT_PUBLIC_BASE_DOMAIN });

export const removeUniversalLoggedInCookie = () => cookies.remove('IS_LOGGED_IN', { path: '/', domain: process.env.NEXT_PUBLIC_BASE_DOMAIN });

export const getUniversalLoggedInCookie = () => cookies.get('IS_LOGGED_IN');

export const TEAM_COOKIE_NAME = 'SA_TEAM';

export const getAuth = () => cookies.get('AUTH');

export const getAuthExpiryDt = () => cookies.get('AUTH_EXPIRY_DT');

export const getExpiryDate = () => addDays(new Date(), 14);

export const setAuth = (authObject, domain, expiryValue = getExpiryDate()) => {
  const options = { path: '/', expires: expiryValue };
  if (domain) {
    options.domain = domain;
  }

  cookies.set('AUTH', JSON.stringify(authObject), options);
  cookies.set('AUTH_EXPIRY_DT', expiryValue, options);
  return authObject;
};

export const removeAuth = () => {
  cookies.remove('AUTH', { path: '/' });
  cookies.remove('AUTH_EXPIRY_DT', { path: '/' });

  removeAuthFromDomain();
  removeUniversalLoggedInCookie();
};

export const removeAuthFromDomain = () => {
  cookies.remove('AUTH', { path: '/', domain: process.env.NEXT_PUBLIC_BASE_DOMAIN });
  cookies.remove('AUTH_EXPIRY_DT', { path: '/', domain: process.env.NEXT_PUBLIC_BASE_DOMAIN });
};

export const isInRole = (role, user) => user.roles && user.roles.includes(role);

export const isAuthenticated = (user) => user != null && user.token && user.userRole !== 'guest';

export const isAdmin = () => {
  const auth = cookies.get('AUTH');

  if (!auth) return false;

  return auth.userRole === 'admin';
};

export const getCurrentTeamToken = () => cookies.get(TEAM_COOKIE_NAME);

export const setCurrentTeamToken = (token) => cookies.set(TEAM_COOKIE_NAME, token, { path: '/' });

export const removeCurrentTeamToken = () => {
  cookies.remove(TEAM_COOKIE_NAME, { path: '/' });
  cookies.remove(TEAM_COOKIE_NAME, { path: '/', domain: process.env.NEXT_PUBLIC_BASE_DOMAIN });
};

export const setBanner = (domain, expiryValue = addDays(new Date(), 14)) => {
  const options = { path: '/', expires: expiryValue };
  if (domain) {
    options.domain = domain;
  }

  cookies.set('VIMEO_SHOW_BANNER', 'true', options);
  cookies.set('YOUTUBE_SHOW_BANNER', 'true', options);
  cookies.set('WEBEX_SHOW_BANNER', 'true', options);
  cookies.set('MEET_SHOW_BANNER', 'true', options);
  cookies.set('LINKEDIN_SHOW_BANNER', 'true', options);
};

export const getBanner = (cookieName) => cookies.get(cookieName);

export const removeBanner = (cookieName) => {
  cookies.remove(cookieName, { path: '/' });
};
