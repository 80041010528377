const DropDownItemContainer = ({
  children, className, disabled, tabIndex, onKeyDown, onClick, ...properties
}) => {
  let cls = 'border-md mx-4 my-2 rounded-md';
  if (!disabled) {
    cls += ' hover:bg-neutral-10 cursor-pointer';
  } else {
    cls += ' text-neutral-50 cursor-not-allowed ';
  }

  let handleKeyDown = onKeyDown;
  let handleOnClick = onClick;

  if (disabled) {
    handleKeyDown = () => { };
    handleOnClick = () => { };
  }

  return <div
    onKeyDown={handleKeyDown}
    onClick={handleOnClick}
    tabIndex={tabIndex}
    className={` ${cls} ${className} line-clamp-1`}
    {...properties}>
    {children}
  </div>;
};

export default DropDownItemContainer;
