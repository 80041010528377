import React from 'react';
import PropTypes from 'prop-types';

const Container = ({
  children, type = 'type1', className, LRpadding = 'px-14 lg:px-0', mxauto = 'mx-auto', ...property
}) => {
  const contentType = {
    type0: 'type0',
    type1: 'type1',
    type2: 'type2',
    type3: 'type3',
    type4: 'type4',
    zoom: 'zoom'
  };
  const containerStyle = {
    type0: 'w-11/12 md:max-w-320 mx-auto',
    type1: `w-full md:max-w-320 mx-auto ${LRpadding}`,
    type2: 'w-full md:max-w-210 mx-auto',
    type3: 'w-full md:max-w-155 mx-auto',
    type4: `w-full md:max-w-265 ${mxauto}`,
    type5: `w-full md:max-w-156 ${mxauto}`,
    zoom: 'w-full md:max-w-320 mx-auto',
    type6: 'w-full max-w-[900px] mx-auto',
    type7: 'w-full max-w-[1200px] mx-auto',
    type8: 'w-full max-w-[800px] mx-auto',
  };
  return (
    <div type={contentType[type]} className={`${containerStyle[type]} ${className || ''}`} {...property}>
      {children}
    </div>
  );
};
Container.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Container;
