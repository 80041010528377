export const getRandomInt = (min, max, inclusive) => {
  const tMin = Math.ceil(min);
  const tMax = Math.floor(max);
  if (inclusive) {
    // The maximum is inclusive and the minimum is inclusive
    return Math.floor(Math.random() * (tMax - tMin + 1) + tMin);
  }
  // The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (tMax - tMin) + tMin);
};

export default getRandomInt;
