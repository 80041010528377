import { groupBy } from 'lodash';
export const defaultState = {
  filters: {
    linkType: 'All'
  },
  links: [],
  allLinks: [],
  groupedLinks: {}
};

export const ActionType = {
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_LINKS: 'SET_LINKS',
  SET_ALL_LINKS: 'SET_ALL_LINKS',
  ADD_LINK: 'ADD_LINK',
  DELETE_LINK: 'DELETE_LINK',
  RESET_STATES: 'RESET_STATES',
  UPDATE_GROUPED_DELETED_LINKS: 'UPDATE_GROUPED_DELETED_LINKS'
};

export const LinkLibraryReducer = (state, action) => {
  switch (action.type) {
    case ActionType.UPDATE_FILTERS:
      return { ...state, filters: { ...action.filters } };
    case ActionType.RESET_FILTERS:
      return { ...state, filters: { ...defaultState.filters } };
    case ActionType.SET_LINKS:
      return { ...state, links: [...action.links] };
    case ActionType.SET_ALL_LINKS:
      return { ...state, allLinks: [...action.links] };
    case ActionType.ADD_LINK:
      if (action.index) {
        return { ...state, links: [...state.links].splice(action.index, 0, action.link) };
      }
      return { ...state, links: [...state.links, action.link] };
    case ActionType.DELETE_LINK: {
      const tmpLinks = [...state.links];
      const tmpAllLinks = [...state.allLinks];
      const index = tmpLinks.findIndex((link) => link?.id === action?.link?.id);
      if (index > -1) {
        tmpLinks.splice(index, 1);
      }
      const index2 = tmpAllLinks.findIndex((link) => link?.id === action?.link?.id);
      if (index2 > -1) {
        tmpAllLinks.splice(index2, 1);
      }
      return { ...state, links: tmpLinks, allLinks: tmpAllLinks };
    }
    case ActionType.UPDATE_GROUPED_DELETED_LINKS: {
      const link = action.links;
      const tmpLinks = { ...state.groupedLinks };

      const url = link?.body?.toLowerCase();
      let temp = { ...state.groupedLinks };
      if (!temp[url]) {
        temp = { ...temp, [url]: link };
        tmpLinks[url] = link;
      }

      return {
        ...state,
        groupedLinks: tmpLinks
      };
    }
    case ActionType.RESET_STATES: {
      return { ...state, links: [], allLinks: [], groupedLinks: {} };
    }
    default:
      return state;
  }
};
