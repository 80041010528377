/* eslint-disable no-param-reassign */
import PropTypes from 'prop-types';

const Heading = ({
  children,
  type,
  fontFamily = 'font-Inter',
  fontSize,
  fontWeight = 'font-normal',
  lineHeight,
  ...property
}) => {
  const issubheading = type?.includes('subheading');
  const HeadingVariants = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6'
  };
  const subHeadingStyle = {
    subheading1: 'subheading-1 text-2xl leading-8',
    subheading2: 'subheading-2 text-base leading-6 ',
    subheading3: 'subheading-3 text-sm leading-5'
  };

  let Type = HeadingVariants[type] ? HeadingVariants[type] : 'h1';
  Type = issubheading ? 'p' : Type;
  property.className = issubheading ? `${subHeadingStyle[type] || ''} ${property.className || ''}` : `${property.className || ''}`;
  property.className += ` ${fontFamily || ''}`;
  property.className += ` ${fontSize || ''}`;
  property.className += ` ${fontWeight || ''}`;
  property.className += ` ${lineHeight || ''}`;

  if (!issubheading && !property.className) delete property.className;
  return (
    <Type {...property} >
      {children}
    </Type>
  );
};

export default Heading;
Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
