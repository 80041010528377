import {
  postWithOutAuth
} from './http.service';

import {
  UrlParamsReplace
} from './url.service';

export const GUEST_LOGIN_URL = () => UrlParamsReplace('login/guest-mode');

export const getGuestLoginToken = (data) => postWithOutAuth(GUEST_LOGIN_URL(), data, data?.email);
