import { getAuth } from '@services/identity.service';
import { getCelloJwtToken } from '@services/youtube-platform.service';
import { useEffect, useState } from 'react';

export const useCelloJwtToken = () => {
  const [token, setToken] = useState<any>('');

  const getData = async (auth: any) => {
    try {
      const tempToken = await getCelloJwtToken(auth?.id);
      if (token === '') { setToken(tempToken); }
    } catch (error) {
      console.log('Failed to fetch token ');
    }
  };

  useEffect(() => {
    const auth = getAuth();

    if (auth?.id) {
      getData(auth);
    }
  }, []);
  return token;
};
