import { type RGBColor, diff, rgb_to_lab } from 'color-diff';

let cacheColor: string | null = null;
let cachePalette: string[] = [];
const threshold = 25;

function chromaPaletteParser() {
  function normalizeHex(hex: string) {
    hex = hex.replace('#', '');
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    return '#' + hex.toUpperCase();
  }

  function hexToRGB(chromaColor: string): RGBColor {
    const normalizedHex = normalizeHex(chromaColor);
    const R = parseInt(normalizedHex.substring(1, 3), 16);
    const G = parseInt(normalizedHex.substring(3, 5), 16);
    const B = parseInt(normalizedHex.substring(5, 7), 16);
    return { R, G, B };
  }

  function parse(palette: string[] = [], chromaColor: string) {
    if (!chromaColor || ![3, 6].includes(chromaColor?.replace('#', '').length)) return palette;

    if (cacheColor === chromaColor) return cachePalette;

    const newPalette: string[] = [];

    palette.forEach(hex => {
      const difference = diff(rgb_to_lab(hexToRGB(chromaColor)), rgb_to_lab(hexToRGB(hex)));
      if (difference > threshold) {
        newPalette.push(hex);
      }
    });

    cachePalette = [...newPalette];
    cacheColor = chromaColor;

    return newPalette;
  }

  return { parse };
}

export {
  chromaPaletteParser
};
