import PropTypes from 'prop-types';

const Text = ({
  children, name, type, color = 'color', onClick, tabIndex, onKeyDown, ...property
}) => {
  const textType = {

    bodyStyleLarge: 'bodyStyleLarge',
    bodyStyleDefault: 'bodyStyleDefault',
    bodyStyleSmall: 'bodyStyleSmall',

    captionSmall: 'captionSmall',
    captionExtraSmall: 'captionExtraSmall'
  };

  const textStyle = {

    bodyStyleLarge: 'Body-Large',
    bodyStyleDefault: 'Body-Default',
    bodyStyleSmall: 'Body-Small',

    captionSmall: 'Caption-Small',
    captionExtraSmall: 'Caption-Extra-Small'
  };

  return (
    <p {...property} type={type ? [textType[type], name] : ''} tabIndex={tabIndex} onKeyDown={onKeyDown} onClick={onClick} className={`${type ? textStyle[type] : ''} ${property.className} ${color}`}>{children}</p>
  );
};

export default Text;

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};
