import React from 'react';
import Text from '../atoms/Text';
import Heading from '../atoms/Heading';

const InfoGuide = ({
  heading, text, video, length, index
}) => (
  <div className={`flex gap-8 justify-center ${index !== length - 1 ? 'mb-6' : ''}`}>
    <div className='overflow-hidden'>
      <iframe allowfullscreen title="vimeo-player" src={video} frameborder="0" width="376" height="192" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
    </div>
    <div className='my-auto max-w-94'>
      <Heading type={'h6'} fontSize='text-lg' fontWeight='font-semibold' lineHeight='leading-7' className='mb-2 text-left'>{heading}</Heading>
      <Text type='bodyStyleSmall' color='text-neutral-60'>{text}</Text>
    </div>
  </div>
);

export default InfoGuide;
