interface BadgeProps {
  variant?:
    | 'primary'
    | 'success'
    | 'info'
    | 'danger'
    | 'disabled'
    | 'default'
    | 'warning'
    | 'soon';
  children: React.ReactNode;
  className?: string;
}

const Badge = ({ variant = 'default', children, className }: BadgeProps) => {
  const variantStyles = {
    primary: 'bg-primary-500 dark:bg-white text-white',
    success: 'bg-green-500 dark:bg-white text-white',
    info: 'bg-blue-300 dark:bg-white',
    danger: 'bg-red-300 dark:bg-white',
    disabled: 'bg-trueGray-300 dark:bg-white',
    default: 'bg-white dark:bg-white',
    warning: 'bg-pure-orange-500',
    soon: 'bg-grey-100 dark:bg-white',
  };

  const styles = variantStyles[variant] || variantStyles.default;

  return (
    <span
      className={`flex items-center select-none text-xs font-medium tracking-wide px-1 py-0.5 rounded-[3px] dark:text-trueGray-800 text-trueGray-800 ${styles} ${className}`}
    >
      {children}
    </span>
  );
};

export default Badge;
