import React from 'react';
import { getKeyValue } from '@nextui-org/react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@atoms/new/dropdown';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Button from '@atoms/new/button';

interface CellDataProps {
  columnKey: any;
  item: any;
  deleteItem: (id: string) => void;
  isOwner?: boolean;
}
export default function CellData({
  columnKey,
  item,
  deleteItem,
  isOwner
}: CellDataProps) {
  if (getKeyValue(item, columnKey) === 'Pending') {
    return <Button
      size='sm'
      onClick={() => deleteItem(item.key)}
      kind='secondary'
      isDisabled={!isOwner}
    >Revoke</Button>;
  }

  if (columnKey !== 'role' || item.role === 'Admin' || (!isOwner && columnKey === 'role')) { return getKeyValue(item, columnKey); }

  return <Dropdown>
    <DropdownTrigger>
      <button className='border border-grey-200 px-4 h-8 gap-1 font-semibold rounded-md text-sm'>
        {getKeyValue(item, columnKey)}
        <KeyboardArrowDownRoundedIcon fontSize='small'/>
      </button>
    </DropdownTrigger>
    <DropdownMenu aria-label="Dynamic Actions">
      <DropdownItem
        // color={item.key === "delete" ? "danger" : "default"}
        // className={item.key === "delete" ? "text-danger" : ""}
        onAction={() => deleteItem(item.key)}
      >
        <div className='text-pure-red-600 flex gap-2 text-sm items-center'>
          <DeleteOutlineRoundedIcon fontSize='small'/>
           Remove from workspace
        </div>
      </DropdownItem>
    </DropdownMenu>
  </Dropdown>;
}
