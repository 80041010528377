import { getAuth, isAuthenticated } from '@services/identity.service';
import { isAdminRoute, isProtected, guestModeAllowed } from './Security';
import { useIsFirstRender } from '@uidotdev/usehooks';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';

const isBrowser = () => typeof window !== 'undefined';

const WithAuth = ({ router, children }) => {
  const auth = getAuth();
  const posthog = usePostHog();
  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (!isFirstRender) return;

    if (isAuthenticated(auth)) {
      posthog?.capture('Logged In');
    } else {
      posthog?.capture('Logged Out');
    }
  }, [isFirstRender]);

  if (['development', 'sandbox', 'qa1'].includes(process.env.NEXT_PUBLIC_ENV_URL) && router.pathname.includes('sandbox')) {
    return children;
  }

  if (auth && auth.userRole === 'guest' && guestModeAllowed(router.pathname)) {
    return children;
  }

  if (process.env.NEXT_PUBLIC_ENV_URL === 'sandbox' && !router.pathname.includes('/sandbox')) {
    router.replace('/sandbox');
  }

  if (isBrowser() && !isAuthenticated(auth) && isProtected(router.pathname)) {
    router.replace({ pathname: '/login', query: { next: router.pathname, ...router.query } });
  }
  if (auth && isAdminRoute(router.pathname) && auth.userRole !== 'admin') {
    router.replace('/landing');
  }
  return children;
};

export default WithAuth;
