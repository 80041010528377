import Link from 'next/link';
import { useRouter } from 'next/router';
import Logo from '../atoms/Logo';
import Button from '../atoms/Button';
import Image from '../atoms/Image';

const Header = () => {
  const router = useRouter();
  return (
    <div className="header-wrapper z-10 ">
      <div className="px-10 py-6 rounded-md shadow-header">
        <div className="top-header flex justify-between items-center">
          <Link href="/">
            <Logo src="/images/WordsWorth-logo.svg" />
          </Link>
          <div>
            <Button onClick={() => router.push('/landing')} className="pr-8">Home</Button>
            <Button onClick={() => router.push('/colors')} className="pr-8">Colors</Button>
            <Button onClick={() => router.push('/tutorial')} className="pr-16">Help</Button>
            <Button className="">Login</Button>
          </div>
        </div>
      </div>
      <div className="shape-wrapper ">
        <div className="full-container mx-auto px-20 ">
          <div className="w-full pb-2 flex justify-end ">
            <Image src={'/images/Banner-Image.svg'} className=" md:w-auto w-1/2" />
          </div>
        </div>
      </div>
    </div>

  );
};

export default Header;
Header.propTypes = {

};
