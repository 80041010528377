const defaultProps = {
  w: 8,
  h: 8,
  textClass: 'text-md',
  bgClass: 'bg-black'
};

const teamColors = [
  ['bg-yellow-500', 'bg-yellow-300'],
  ['bg-red-500', 'bg-red-300'],
  ['bg-orange-500', 'bg-orange-300'],
  ['bg-lime-500', 'bg-lime-300'],
  ['bg-emerald-500', 'bg-emerald-300']

];

const profileColors = [
  ['bg-sky-500', 'bg-sky-300'],
  ['bg-indigo-500', 'bg-indigo-300'],
  ['bg-purple-500', 'bg-purple-300'],
  ['bg-pink-500', 'bg-pink-300'],
  ['bg-cyan-500', 'bg-cyan-300']
];

// eslint-disable-next-line no-bitwise
export const hashCode = (str = '') => str.split('').reduce((prevHash, currVal) => (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);

export const getColorFromString = (str, index, isProfile) => {
  const hash = Math.abs(hashCode(str));
  const first2Num = Number(String(hash).slice(0, 2));
  const colorArray = isProfile ? profileColors : teamColors;
  return colorArray[(first2Num) % colorArray.length][index];
};

const Avatar = ({
  name = '', isProfile = false, isOpen = false, ...property
}) => {
  const props = { ...defaultProps, ...property };

  const color = getColorFromString(name, props.disabled ? 1 : 0, isProfile);

  const bgClass = `flex justify-center items-center w-full h-full overflow-hidden text-center shadow-inner cursor-pointer group ${isProfile ? 'rounded-full' : 'rounded-md'} ${isProfile && isOpen && 'rounded-full border-2 border-black'} ${color} ${props.bgClass}`;

  return (
    <div className={`w-${props.w} h-${props.h} inline-block text-center leading-8 ${props.className}`}>
      <div className={bgClass}>
        <span className={`font-bold leading-10 align-middle text-white group-hover:table-cell ${props.textClass}`}>{name[0]?.toUpperCase()}</span>
      </div>
    </div>
  );
};

export default Avatar;
