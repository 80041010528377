/* eslint-disable import/prefer-default-export */
import { postWithOutAuth } from './http.service';
import { LOG_TO_AWS_CLOUDWATCH_URL } from './url.service';

export const logsToAwsCloudWatch = (body: any) => postWithOutAuth(LOG_TO_AWS_CLOUDWATCH_URL(), body);

export const logToCloudWatch = (message: string, body: object, level: string = 'INFO') => {
  logsToAwsCloudWatch({
    logMessage: message,
    logData: body,
    level
  });
};
