import React from 'react';

const Logo = React.forwardRef(({ ...property }, ref) => (
  <img
    ref={ref}
    onClick={property.onClick}
    src={property.src}
    width={property.width}
    height={property.height}
    alt={property.alt}
    className={property.className}
  />
));

export default Logo;
