import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ ...props }) => (
  <img {...props} />
);

export default Image;

Image.propTypes = {
  className: PropTypes.string
};
