const Footer = () => (
  <div className="footer bg-black md:w-full">
    <div className="container md:m-auto flex justify-center lg:py-4.5 md:py-4 py-3 md:px-0 px-18">
      <a className="text-white font-medium md:text-xs text-tiny md:leading-6 leading-4.5 md:mr-12 mr-6" href="https://streamalive.com/terms-of-service" target='_blank' >Terms of Service</a>
      <a className="text-white font-medium md:text-xs text-tiny md:leading-6 leading-4.5 md:ml-12 ml-4" href="https://streamalive.com/privacy" target='_blank'>Privacy Policy</a>
    </div>
  </div>
);

export default Footer;
