import Button from '@atoms/new/button';
// import Input from '@atoms/new/input';
import React, { useContext, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupValidator } from '@lib/yup-validator';
import { TeamsDataContext } from '@components/context/TeamsContext';
import { updateTeamApi } from '@services/youtube-platform.service';
import Input from '@atoms/Input';
import { notify } from '@components/Presentation/helpers';
import Tooltip from '@atoms/new/tooltip';

interface WorkspaceNameProps {
  adminTeam: any;
  onSuccess: () => void;
  isOwner: boolean;
}
export default function WorkspaceName({
  adminTeam,
  onSuccess,
  isOwner
}: WorkspaceNameProps) {
  const {
    currentTeam,
    updateTeam,
    setTeamErrors,
    setTeamMessages
  } = useContext(TeamsDataContext);

  const [defaultName, setDefaultName] = useState(currentTeam?.billing_account?.name);

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    formState: {
      isDirty,
      errors
    }
  } = useForm({
    defaultValues: {
      name: defaultName
    },
    resolver: yupValidator(
      yup.object().shape({
        name: yup
          .string()
          .required('Please enter a team name.')
          .max(20, 'Workspace name must be 20 characters or fewer.')
          .test(
            'is-not-only-spaces',
            'Name must have at least 1 alphanumeric character.',
            (value) => value.length === 0 || value.trim() !== ''
          )
          .test(
            'is-not-same-name',
            'Name must be different from the current one.',
            (value) => value.trim() !== adminTeam?.name
          )

      })
    )
  });

  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ name }: { name: string }) => {
    if (!adminTeam || !isOwner) return;
    setLoading(true);
    try {
      const result = await updateTeamApi(adminTeam.id, { name: name.trim() });
      if (result.status) {
        setDefaultName(name);
        // onUpdate(result.entity);
        notify(result.message);
        onSuccess();
        updateTeam(result.entity);
        setTeamMessages([result.message]);
      } else {
        setTeamErrors(result.message);
      }
    } catch (error) {
      setTeamErrors('Something went wrong. Please try again.');
      notify('Something went wrong. Please try again.', 'error');
    } finally {
      reset({ name });
    }

    setLoading(false);
  };

  return (
    <>
      <label className='text-sm font-semibold'>Workspace name</label>
      <div className='flex mt-2'>
        <Tooltip
          content='Only admin can update the workspace name.'
          placement='top-start'
          isDisabled={isOwner}
        >
          <Input
            {...register('name')}
            isInvalid={!!errors.name}
            placeholder="Workspace name"
            className='flex-1 h-9.5'
            disabled={!isOwner}
          />
        </Tooltip>
        <div className='flex-1'>
          {isDirty && <>
            <Button
              className='ml-3'
              onClick={handleSubmit(onSubmit)}
              isLoading={loading}
            >
                  Update
            </Button>
            <Button
              className='ml-2'
              kind='secondary'
              onClick={() => resetField('name')}
            >
                  Cancel
            </Button>
          </>}
        </div>
      </div>
      {errors?.name && <p className='mt-2 text-pure-red-600 text-sm'>{errors.name?.message as string}</p>}
    </>
  );
}
