import {
  Tabs as NextUITabs,
  type TabsProps as NextUITabsProps,
  Tab as NextUITab,
} from '@nextui-org/tabs';

interface TabsProps extends NextUITabsProps {}

export const Tabs = (props: TabsProps) => {
  return (
    <NextUITabs
      variant="underlined"
      classNames={{
        panel: 'p-0',
        tabList:
          'gap-6 w-full relative rounded-none p-0 border-b border-divider',
        cursor: 'w-full bg-primary-500',
        tab: 'max-w-fit px-0 py-0 mx-0 h-[42px]',
        tabContent:
          'text-grey-900 group-data-[selected=true]:text-primary-500 group-hover-[unselected=true]:text-grey-800',
      }}
      {...props}
    />
  );
};

export const Tab = NextUITab;
